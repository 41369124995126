import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Header from './components/Header';
import Banner from './components/Banner';
import Showroom from './components/Showroom';
import AppPromo from './components/AppPromo';
import Footer from './components/Footer';
import Loading from './components/Loading';
import AdPopup from './components/AdPopup';

const Login = React.lazy(() => import('./pages/Login'));
const Register = React.lazy(() => import('./pages/Register'));
const Profile = React.lazy(() => import('./pages/Profile'));
const AddAd = React.lazy(() => import('./pages/AddAd'));
const AdDetail = React.lazy(() => import('./pages/AdDetail'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Message = React.lazy(() => import('./pages/Message'));
const Payment = React.lazy(() => import('./pages/Payment'));
const PaymentSuccess = React.lazy(() => import('./pages/PaymentSuccess'));
const PaymentFail = React.lazy(() => import('./pages/PaymentFail'));
const AllAds = React.lazy(() => import('./pages/AllAds'));
const CorporateSignup = React.lazy(() => import('./pages/CorporateSignup'));
const AdminPanel = React.lazy(() => import('./pages/AdminPanel'));

// Yeni eklenen sayfalar
const About = React.lazy(() => import('./pages/About'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));

const AppContent = ({ isLoggedIn, setIsLoggedIn }) => {
    const location = useLocation();

    const showFooter = location.pathname !== '/payment';
    const showAdPopup = location.pathname === '/';

    return (
        <div className="page-container">
            {showAdPopup && <AdPopup />}
            <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            <div className="content-wrap">
                <Suspense fallback={<Loading />}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <>
                                    <Banner />
                                    <Showroom />
                                    <AppPromo />
                                </>
                            }
                        />
                        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/add-ad" element={<AddAd />} />
                        <Route path="/ad/:id" element={<AdDetail />} />
                        <Route path="/message/:userId/:adId" element={<Message />} /> {/* Güncellenmiş rota */}
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/payment" element={<Payment />} />
                        <Route path="/payment-success" element={<PaymentSuccess />} />
                        <Route path="/payment-fail" element={<PaymentFail />} />
                        <Route path="/ads" element={<AllAds />} />
                        <Route path="/corporate-signup" element={<CorporateSignup />} />
                        <Route path="/admin" element={<AdminPanel />} /> {/* Admin Panel Rotası */}
                        {/* Yeni eklenen sayfa rotaları */}
                        <Route path="/about" element={<About />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    </Routes>
                </Suspense>
            </div>
            {showFooter && <Footer />}
        </div>
    );
};

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));

    return (
        <Router>
            <AppContent isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        </Router>
    );
}

export default App;