import React from 'react';
import './Loading.css'; // Stil dosyasını oluşturmayı unutmayın

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="spinner" aria-label="Yükleniyor"></div>
            <p>Yükleniyor...</p>
        </div>
    );
};

export default Loading;