// frontend/src/components/FeaturedAds.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './FeaturedAds.css';
import config from '../config';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import placeholderImage from '../assets/placeholder-image.png';

const FeaturedAds = () => {
    const [ads, setAds] = useState([]);

    useEffect(() => {
        const fetchFeaturedAds = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/ads?premium=featured`);
                setAds(response.data.ads);
            } catch (error) {
                console.error('Featured ads fetch error:', error);
                toast.error('Featured ilanlar çekilirken bir hata oluştu.');
            }
        };

        fetchFeaturedAds();
    }, []);

    const placeholders = Array(4).fill({
        title: 'Boş İlan',
        price: 'Bilinmiyor',
        location: 'Bilinmiyor',
        images: [],
    });

    const displayedAds = ads.length > 0 ? ads.slice(0, 4) : placeholders;

    const isPremium = (ad) => {
        return ['featured', 'slider', 'showroom'].some(
            (feature) => ad[feature]?.isActive && new Date(ad[feature].expiryDate) > new Date()
        );
    };

    const getPremiumLabel = (ad) => {
        const now = new Date();
        if (ad.featured?.isActive && new Date(ad.featured.expiryDate) > now) {
          return 'V.I.P';
        }
        if (ad.slider?.isActive && new Date(ad.slider.expiryDate) > now) {
          return 'Slider';
        }
        if (ad.showroom?.isActive && new Date(ad.showroom.expiryDate) > now) {
          return 'Showroom';
        }
        return null;
      };

    return (
        <div className="featured-ads">
            {displayedAds.map((ad, index) => (
                <Link to={`/ad/${ad._id}`} key={ad._id || index} className="featured-item-link">
                    <div className={`featured-item ${isPremium(ad) ? 'premium' : ''}`}>
                        {isPremium(ad) && (
                            <div className="ribbon">
                                <span>{getPremiumLabel(ad)}</span>
                            </div>
                        )}
                        <div className="featured-image-container">
                            <img
                                src={
                                    ad.images && ad.images.length > 0
                                        ? `${config.baseUrl}/uploads/${ad.images[0]}` // Burada config.baseUrl kullanıldı
                                        : placeholderImage
                                }
                                alt={ad.title}
                                className="featured-image"
                            />
                        </div>
                        <div className="featured-info">
                            <h3>{ad.title}</h3>
                            <p className="price">{ad.price} ₺</p>
                            <p className="location">{ad.location}</p>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default FeaturedAds;
