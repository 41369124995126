// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="logo-container">
                    <img src="/logowhite.png" alt="İlanPorts Logo" className="footer-logo" />
                </div>
                <div className="footer-sections">
                    <div className="quick-links">
                        <h3>Hızlı Linkler</h3>
                        <a href="/contact">İletişim</a>
                        <a href="/about">Hakkımızda</a>
                        <a href="/privacy-policy">Gizlilik Politikası</a>
                    </div>
                    <div className="contact-info">
                        <h3>İletişim Bilgileri</h3>
                        <p>E-posta: ilanport@ilanports.com</p>
                        <p>Adres: Girne, Kuzey Kıbrıs Türk Cumhuriyeti</p>
                    </div>
                    <div className="social-icons">
                        <h3>Bizi Takip Edin</h3>
                        <div className="icons-wrapper">
                            <a href="#" aria-label="Facebook">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="#" aria-label="Twitter">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="#" aria-label="Instagram">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="#" aria-label="LinkedIn">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 İlanPorts. Tüm hakları saklıdır.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;