import React from 'react';
import './AppPromo.css'; // Stil dosyasını içe aktarıyoruz

const AppPromo = () => {
    return (
        <div className="promo-section">
            {/* Sol: Reklam Alanı */}
            <div className="promo-advertisement">
                <div className="video-container">
                    <video width="100%" height="auto" loop autoPlay muted>
                        <source src="/bestburgerads.mp4" type="video/mp4" />
                        Tarayıcınız bu videoyu desteklemiyor.
                    </video>
                </div>
            </div>

            {/* Sağ: Mobil Uygulama Tanıtımı */}
            <div className="promo-app">
                <h2>Mobil Uygulamamızı İndirin</h2>
                <p>İlanlarınıza kolayca ulaşın, fırsatları kaçırmayın!</p>
                <div className="download-buttons">
                    <a href="#" className="download-button google-play" aria-label="Google Play'den İndir">
                        <i className="fab fa-google-play"></i> Google Play
                    </a>
                    <a href="#" className="download-button app-store" aria-label="App Store'dan İndir">
                        <i className="fab fa-apple"></i> App Store
                    </a>
                </div>
            </div>
        </div>
    );
};

export default AppPromo;
