// frontend/src/components/Slider.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Slider.css';
import config from '../config';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Slider = () => {
    const [ads, setAds] = useState([]);

    useEffect(() => {
        const fetchSliderAds = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}/ads?premium=slider`);
                setAds(response.data.ads);
            } catch (error) {
                console.error('Slider ads fetch error:', error);
                toast.error('Slider ilanlar çekilirken bir hata oluştu.');
            }
        };

        fetchSliderAds();
    }, []);

    // Placeholder veya gerçek ilanları kullan
    const placeholders = Array(10).fill(null);
    const sliderAds = ads.length > 0 ? ads : placeholders;

    // Premium özelliğin aktif olup olmadığını kontrol eden fonksiyon
    const isPremium = (ad) => {
        return ['featured', 'slider', 'showroom'].some(
            (feature) => ad[feature]?.isActive && new Date(ad[feature].expiryDate) > new Date()
        );
    };

    const getPremiumLabel = (ad) => {
        const now = new Date();
        if (ad.featured?.isActive && new Date(ad.featured.expiryDate) > now) {
          return 'V.I.P';
        }
        if (ad.slider?.isActive && new Date(ad.slider.expiryDate) > now) {
          return 'Slider';
        }
        if (ad.showroom?.isActive && new Date(ad.showroom.expiryDate) > now) {
          return 'Showroom';
        }
        return null;
      };

    // Slider öğelerini oluşturun
    const sliderItems = sliderAds.map((ad, index) => {
        if (ad) {
            return (
                <Link to={`/ad/${ad._id}`} key={ad._id || index} className="slider-item-link">
                    <div className="slider-item">
                        {isPremium(ad) && (
                            <div className="ribbon">
                                <span>{getPremiumLabel(ad)}</span>
                            </div>
                        )}
                        <div className="slider-image-container">
                            <img
                                src={
                                    ad.images && ad.images.length > 0
                                        ? `${config.baseUrl}/uploads/${ad.images[0]}` // Burada config.baseUrl kullanıldı
                                        : '/images/placeholder-image.jpg'
                                }
                                alt={ad.title}
                                className="slider-image"
                            />
                        </div>
                        <div className="slider-info">
                            <h3>{ad.title}</h3>
                            <p className="price">{ad.price} {ad.currency}</p>
                        </div>
                    </div>
                </Link>
            );
        } else {
            // Placeholder
            return (
                <div key={index} className="slider-item placeholder-item">
                    <div className="slider-image-container">
                        <img src="/placeholder-image.png" alt="Placeholder" className="slider-image" />
                    </div>
                    <div className="slider-info">
                        <h3>Boş İlan {index + 1}</h3>
                        <p className="price">Fiyat Bilinmiyor</p>
                    </div>
                </div>
            );
        }
    });

    // Slider track uzunluğunu dinamik olarak ayarlamak için referans kullan
    const sliderTrackRef = React.useRef(null);

    return (
        <div className="slider">
            <div className="slider-track" ref={sliderTrackRef}>
                {sliderItems}
            </div>
        </div>
    );
};

export default Slider;
