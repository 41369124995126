import React from 'react';
import './AdTemplate.css'; // Şablon stil dosyasını ekleyelim

const AdTemplate = () => {
    return (
        <div className="item-card template-card">
            <div className="image-container">
                <img src="/placeholder-image.png" alt="Placeholder" className="item-image" />
            </div>
            <div className="item-info">
                <h3>Boş İlan</h3>
                <p className="price">Fiyat Bilinmiyor</p>
                <p className="location">Konum Bilinmiyor</p>
            </div>
        </div>
    );
};

export default AdTemplate;